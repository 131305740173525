<template>
  <!-- Claim Details Component -->
  <slot></slot>
  <div class="row">
    <div class="col-6">
      <div class="card">
        <event-image
          key="entryimage"
          class="card-img-top"
          :filename="claim.events[0].image"
          :site_id="claim.site.id"
          :id="'eventimage-' + claim.events[0].id"
          :storage="claim.events[0].storage_account"
          :thumb="false"
        ></event-image>
        <div class="card-body">
          <h5 class="card-title">Entry</h5>
          <p class="card-text">
            Entered at
            <timestamp
              :time="claim.events[0].created_at"
              format="HH:mm:ss"
            ></timestamp>
            on
            <timestamp
              :time="claim.events[0].created_at"
              format="dd/MM/yyyy"
            ></timestamp>
          </p>
        </div>
      </div>
    </div>
    <div class="col-6">
      <div class="card">
        <event-image
          key="exitimage"
          class="card-img-top"
          :filename="claim.events[1].image"
          :site_id="claim.site.id"
          :id="'eventimage-' + claim.events[1].id"
          :storage="claim.events[1].storage_account"
          :thumb="false"
        ></event-image>
        <div class="card-body">
          <h5 class="card-title">Exit</h5>
          <p class="card-text">
            Exited at
            <timestamp
              :time="claim.events[1].created_at"
              format="HH:mm:ss"
            ></timestamp>
            on
            <timestamp
              :time="claim.events[1].created_at"
              format="dd/MM/yyyy"
            ></timestamp>
          </p>
        </div>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-4">
      <div class="card widget widget-stats">
        <div class="card-body">
          <div class="widget-stats-container d-flex">
            <div class="widget-stats-content flex-fill">
              <span class="widget-stats-title">Maximum Stay</span>
              <span class="widget-stats-amount"
                ><span v-if="max_stay.hours > 0"
                  >{{ max_stay.hours }} hours</span
                >
                <span>{{ max_stay.minutes }} minutes</span></span
              >
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-4">
      <div class="card widget widget-stats">
        <div class="card-body">
          <div class="widget-stats-container d-flex">
            <div class="widget-stats-content flex-fill">
              <span class="widget-stats-title">Total Stay</span>
              <span class="widget-stats-amount"
                ><span v-if="total_stay.hours > 0"
                  >{{ total_stay.hours }} hours</span
                >
                <span v-if="total_stay.minutes > 0"
                  >{{ total_stay.minutes }} minutes</span
                >
                <span v-if="total_stay.seconds > 0"
                  >{{ total_stay.seconds }} seconds</span
                ></span
              >
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-4">
      <div class="card widget widget-stats">
        <div class="card-body">
          <div class="widget-stats-container d-flex">
            <div class="widget-stats-content flex-fill">
              <span class="widget-stats-title">Overstayed By</span>
              <span class="widget-stats-amount"
                ><span v-if="overstay_by.hours > 0"
                  >{{ overstay_by.hours }} hours</span
                >
                <span v-if="overstay_by.minutes > 0"
                  >{{ overstay_by.minutes }} minutes</span
                >
                <span v-if="overstay_by.seconds > 0"
                  >{{ overstay_by.seconds }} seconds</span
                ></span
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <slot></slot>

  <teleport to="body">
    <div
      class="modal fade"
      id="parkingImageModal"
      tabindex="-1"
      style="display: none; padding-right: 17px"
      aria-modal="true"
      role="dialog"
    >
      <div
        class="modal-dialog modal-dialog-centered"
        style="height: 80vh; width: 75%; max-width: 75%"
      >
        <event-image
          v-if="currentEvent !== null"
          style="width: 100%; height: auto"
          :thumb="false"
          :filename="currentEvent.image"
          :site_id="currentEvent.site_id"
          :id="'eventimage-' + currentEvent.id"
          :storage="currentEvent.storage_account"
        />
        <p class="closePopup">
          Press <code>ESC</code> or click outside of the image to close this
          popup!
        </p>
      </div>
    </div>
  </teleport>
</template>

<script>
import { DateTime } from "luxon";
import EventImage from "../../../../../components/EventImage.vue";
import Timestamp from "../../../../../components/Timestamp.vue";
export default {
  name: "ParkingDetails",
  components: {
    EventImage,
    Timestamp,
  },
  props: {
    claim: Object,
    client_id: String | Number,
  },
  data() {
    return {
      currentEvent: null,
    };
  },
  methods: {
    openEventImage(event) {
      this.currentEvent = event;
      $("#parkingImageModal").modal("toggle");
    },
  },
  computed: {
    total_stay: function () {
      return DateTime.fromSeconds(this.claim.events[1].created_at)
        .diff(DateTime.fromSeconds(this.claim.events[0].created_at), [
          "hours",
          "minutes",
          "seconds",
        ])
        .toObject();
    },
    overstay_by: function () {
      return DateTime.fromSeconds(this.claim.events[1].created_at)
        .diff(
          DateTime.fromSeconds(this.claim.events[0].created_at).plus({
            minutes: parseInt(this.claim.site.max_stay),
          }),
          ["hours", "minutes", "seconds"]
        )
        .toObject();
    },
    max_stay: function () {
      let maxStay = parseInt(this.claim.site.max_stay);
      let hours = (maxStay / 60) >> 0;
      maxStay -= hours * 60;
      return { hours: hours, minutes: maxStay };
    },
  },
};
</script>

<style scoped>
.widget-stats-amount {
  font-size: 16px !important;
}

.widget-stats .widget-stats-container .widget-stats-icon {
  width: 45px;
  height: 45px;
}
.widget-stats .widget-stats-container .widget-stats-icon i {
  line-height: 45px;
  font-size: 21px;
}
</style>
